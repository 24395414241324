import $ from 'jquery';

$(document).ready(function() {

    $('.matchHeight').matchHeight();

    $(document).on('click','#sommaire-article a',function(){
        var h = $(this).attr('href');

        $('body,html').animate({
            scrollTop:$(h).offset().top -120
        }, 500);
        return false;
    });

    if ($("main").hasClass("travailler-chez-splio")) {
        $("footer").hide();
    }


    $(window).on('load', function(){
        setTimeout(function(){
            $('.toggle-btn').parent().toggleClass('active').siblings().removeClass('active');
        }, 1000);
    });

	$(document).on('click', '#taxonomy-agences-cat-list a', function () {
		var h = $(this).attr('href');

		$('body,html').animate({
			scrollTop: $(h).offset().top -120
		}, 800);
		return false;
	});

	/***************************************************/

	$(document).ready(function() {
		const $cursor = $('#progressCursor');
		const $container = $('.progress_timeline');
		const $timelineItems = $('.timeline_repeater');

		function updateCursorPosition() {
			const scrollTop = $(window).scrollTop();
			const containerTop = $container.offset().top;
			const containerHeight = $container.height();
			const windowHeight = $(window).height();

			// Calculer la position relative du curseur
			let relativePosition = (scrollTop + windowHeight / 2 - containerTop) / containerHeight;
			relativePosition = Math.max(0, Math.min(1, relativePosition));

			const cursorPosition = relativePosition * containerHeight;

			$cursor.css('top', cursorPosition + 'px');

			// Mise en surbrillance du titre
			highlightActiveTitle(cursorPosition + containerTop);
		}

		function highlightActiveTitle(cursorGlobalPosition) {
			$timelineItems.each(function() {
				const $item = $(this);
				const itemTop = $item.offset().top;
				const itemBottom = itemTop + $item.outerHeight();
				const $title = $item.find('h3'); // Assurez-vous que ce sélecteur correspond à vos titres

				if (cursorGlobalPosition >= itemTop && cursorGlobalPosition < itemBottom) {
					$title.addClass('highlight-blue');
				} else {
					$title.removeClass('highlight-blue');
				}
			});
		}

		function adjustContainerHeight() {
			let totalHeight = 0;
			$timelineItems.each(function() {
				totalHeight += $(this).outerHeight(true);
			});
			$container.height(totalHeight);
		}

		// Initialisation
		adjustContainerHeight();
		updateCursorPosition();

		// Mise à jour lors du défilement et du redimensionnement
		$(window).on('scroll resize', function() {
			adjustContainerHeight();
			updateCursorPosition();
		});
	});


	$('.openPopup').click(function() {
		$('#popupContainer').addClass('open');
		$('.footer_cta').addClass('d-none');
	});

	$('#closePopup').click(function() {
		$('#popupContainer').removeClass('open');
		$('.footer_cta').removeClass('d-none');
		$('.footer_cta').addClass('d-block');
	});



});


